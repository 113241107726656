import { useContext } from 'react'
import { store } from '../Context/Store'
import { useStaticQuery } from 'gatsby'
import useCurrentLocationIndex from './useCurrentLocationIndex'

const useGlobalContext = () => {
  const { globalState } = useContext(store)
  const configData = useStaticQuery(graphql`
    query allConfigData {
      allConfigData {
        edges {
          node {
            ...allConfigDataFields
          }
        }
      }
    }
  `)
  const getStoriesByTag = tag =>
    globalState?.siteData?.allGeneral?.filter(story => {
      return story?.tags?.includes(tag)
    })

  const getStoriesById = id =>
    globalState?.siteData?.allGeneral?.find(story => {
      return story.id === id
    })

  return {
    ...(globalState?.siteData ?? {}),
    configData: configData.allConfigData?.edges?.[0]?.node,
    // fn's
    getStoriesByTag,
    getStoriesById
  }
}

export default useGlobalContext
